import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Modal } from 'react-bootstrap';
import { getPaises, getProvincias, getlocalidades, LocacionesData } from '@/store/locaciones/locacionesActions';
import { getConsultores, ConsultorData } from '@/store/consultores/consultoresActions';

import { getCertificados, CertificadoData } from '@/store/certificados/certificadosActions';

import { slugify } from '@/components/utils';
import ConsultorCard from '@/components/loading/ConsultorCard';

const Consultores: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [valorPais, setValorPais] = useState('');
  const [valorProvincia, setValorProvincia] = useState('');
  const [valorLocalidad, setValorLocalidad] = useState('');
  const [valorActividad, setValorActividad] = useState('');
  const [valorIdioma, setValorIdioma] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [modalChangeState, setModalChangeState] = useState(true);
  const informacionPaises = useAppSelector(state => state.locaciones?.paisesData);
  const informacionProvincias = useAppSelector(state => state.locaciones?.provinciasData);
  const informacionLocalidades = useAppSelector(state => state.locaciones?.localidadesData);
  const [checked, setChecked] = useState(false);
  const dataConsultores = useAppSelector(state => state.consultores?.consultoresData);
  const dataCertificados = useAppSelector(state => state.certificados?.itemsData);

  // Estado para currentPage con valor inicial 1
  const [currentPage, setCurrentPage] = useState(1);

  // Resto del código

  const itemsPerPage = 30;
  const dataConsultoresCount = dataConsultores?.data.length ?? 0;
  const totalPages = Math.ceil(dataConsultoresCount / itemsPerPage);

  const generateVisiblePages = (maxPagesToShow: number) => {
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
    let startPage = Math.max(1, currentPage - halfMaxPagesToShow);
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    if (endPage - startPage + 1 < maxPagesToShow) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    const pagesArray = [...Array(endPage - startPage + 1)].map((_, index) => startPage + index);

    return pagesArray;
  };

  const handlePageClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const getCurrentItems = () => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return dataConsultores?.data.slice(indexOfFirstItem, indexOfLastItem);
  };

  useEffect(() => {
    const keyStorage = localStorage.getItem('consultores');
    if (keyStorage) {
      setModalChangeState(false);
      localStorage.removeItem('consultores');
    }

    dispatch(getPaises({ only_with_consultor: true }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCertificados({}));
  }, [dispatch]);

  useEffect(() => {
    if (valorPais) {
      dispatch(getProvincias({ id: valorPais, only_with_consultor: true }));
      setValorProvincia('');
    }
  }, [valorPais, dispatch]);

  useEffect(() => {
    if (valorProvincia) {
      dispatch(getlocalidades({ id: valorProvincia, only_with_consultor: true }));
      setValorLocalidad('');
    }
  }, [valorProvincia, dispatch]);

  const handleChangeA = (event: any) => {
    if (event.target.value == '0') {
      setValorPais('');
      setValorProvincia('');
      setValorLocalidad('');
    } else {
      setValorPais(event.target.value);
      setValorProvincia('');
      setValorLocalidad('');
      dispatch(getProvincias({ id: event.target.value, only_with_consultor: true }));
    }
  };

  const handleChangeB = (event: any) => {
    if (event.target.value == '0') {
      setValorProvincia('');
      setValorLocalidad('');
    } else {
      setValorProvincia(event.target.value);
      setValorLocalidad('');
      dispatch(getlocalidades({ id: event.target.value, only_with_consultor: true }));
    }
  };

  const handleChangeC = (event: any) => {
    setValorLocalidad(event.target.value);
  };

  const handleChangeActividad = (event: any) => {
    setValorActividad(event.target.value);
  };

  const handleChangeIdioma = (event: any) => {
    setValorIdioma(event.target.value);
  };

  const viewProfile = (item: ConsultorData) => {
    const path = `/consultor/${item.IdConsultor}-${slugify(item.Nombre)}`;
    navigate(path);
  };

  useEffect(() => {
    dispatch(getConsultores({ page: 0, tipo: 'comunidad' }));
  }, [dispatch]);

  const handleConsultoresClick = () => {
    setCurrentPage(1);
    dispatch(
      getConsultores({
        page: 0,
        IdPais: valorPais,
        IdProvincia: valorProvincia,
        IdLocalidad: valorLocalidad,
        Nombre: inputValue,
        actividad: valorActividad,
        tipo: 'comunidad',
        idioma: valorIdioma,
      }),
    );
  };

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };

  return (
    <>
      {/* MODAL */}

      <Modal dialogClassName="custom-modal" backdrop="static" show={modalChangeState} onHide={() => setModalChangeState(false)}>
        <Modal.Header className="modal-header">
          <Modal.Title>Bienvenido al listado de Consultores Humano Puente</Modal.Title>
        </Modal.Header>

        <Modal.Body className="modal-body">
          <Form.Label>
            {' '}
            <strong>
              Se llama Consultores de Humano Puente a todos aquellos que luego de haber dado los pasos en la Bioexistencia
              Consciente han solicitado figurar en el siguiente listado para ser contactados en caso que alguien desee hacerlo.
            </strong>
          </Form.Label>
          <div className="modal-body">
            <p className="lead"> </p>
            <div className="form-check">
              <label className="form-check-label" htmlFor="flexCheckDefault">
                <p className="small-text">
                  Debido a que Humano Puente, por razones obvias, no puede conocer ni garantizar la suma de abordajes a realizar
                  ni los pasos o diferentes terapias que una persona aplica en su Consulta, al tildar este casillero usted ha sido
                  informado/a y acepta que no existe relación alguna entre el Consultor y Humano Puente y que toda relación que
                  surja entre Consultante y Consultor es de exclusiva responsabilidad de esas dos partes y las implica sólo a
                  ellas (Consultante y su Consultor/a). Este listado solo enumera algunas de las personas que han realizado el
                  camino de Humano Puente.
                </p>
                <p className="small-text">
                  {' '}
                  Humano Puente es un camino de consciencia y bajo ningun aspecto debe ser considerado como una medicina de ningún
                  tipo asi como tampoco una medicina alternativa ni complementaria.
                </p>
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <Button onClick={() => setModalChangeState(false)} variant="btn btn-primary">
            Aceptar y acceder
          </Button>
        </Modal.Footer>
      </Modal>

      <div id="myModal" className="modal fade" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title text-primary">Bienvenido al listado de Consultores Humano Puente.</h3>
            </div>
            <div className="modal-body">
              <div className="form-check">
                <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckDefault" />
                <p className="lead">
                  Se llama Consultores de Humano Puente a todos aquellos que luego de haber dado los pasos en la Bioexistencia
                  Consciente han solicitado figurar en el siguiente listado para ser contactados en caso que alguien desee
                  hacerlo.
                </p>
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  <p className="small-text">
                    Debido a que Humano Puente, por razones obvias, no puede conocer ni garantizar la suma de abordajes a realizar
                    ni los pasos o diferentes terapias que una persona aplica en su Consulta, al tildar este casillero usted ha
                    sido informado/a y acepta que no existe relación alguna entre el Consultor y Humano Puente y que toda relación
                    que surja entre Consultante y Consultor es de exclusiva responsabilidad de esas dos partes y las implica sólo
                    a ellas (Consultante y su Consultor/a). Este listado solo enumera algunas de las personas que han realizado el
                    camino de Humano Puente.
                  </p>
                  <p className="small-text">
                    {' '}
                    Humano Puente es un camino de consciencia y bajo ningun aspecto debe ser considerado como una medicina de
                    ningún tipo asi como tampoco una medicina alternativa ni complementaria.
                  </p>
                </label>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-outline-primary" data-bs-dismiss="modal">
                Acceder
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Breadcrumb */}
      <section className="container d-none d-lg-block mt-2">
        <div className="row">
          <div className="col-12">
            <div className="border-top py-2">
              <nav style={{}} aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Inicio</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Consultores
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      {/* Header */}
      <header className="container my-2 my-md-5">
        <div className="row g-4">
          <div className="col-md-6">
            <h1 className="display-4">Consultores</h1>
          </div>
          <div className="col-md-6 text-start">
            <p className="lead">
              Aquí podrá realizar la búsqueda de un Consultor (personas que han dado los pasos del Camino de Humano Puente) por
              País, Estado o Provincia, ciudad, idioma, atencion presencial o via remota y especialidades o seminarios que ha
              realizado. Gracias y un abrazo.
            </p>
          </div>
        </div>
      </header>
      <section className="container my-2 my-md-5">
        <div className="row g-4">
          <div className="col-12">
            <div className="border-top pt-2 border-dark mb-4">
              <h5 className="text-primary">
                <strong>Buscar consultores</strong>
              </h5>
            </div>
          </div>
        </div>
        <form>
          <div className="row g-4">
            <div className="col-md-6 col-lg-4">
              <select className="form-select" value={valorPais} aria-label="Default select example" onChange={handleChangeA}>
                <option value="">País</option>

                {informacionPaises &&
                  informacionPaises.data.map(opcion => (
                    <option key={opcion.IdPais} value={opcion.IdPais}>
                      {opcion.Nombre}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-6 col-lg-4">
              <select
                className="form-select"
                value={valorProvincia}
                disabled={!valorPais}
                onChange={handleChangeB}
                aria-label="Default select example">
                <option value="">Provincia</option>

                {informacionProvincias &&
                  informacionProvincias.data.map(opcion => (
                    <option key={opcion.IdProvincia} value={opcion.IdProvincia}>
                      {opcion.Nombre}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-6 col-lg-4">
              <select
                className="form-select"
                aria-label="Default select example"
                value={valorLocalidad}
                disabled={!valorProvincia}
                onChange={handleChangeC}>
                <option value="">Localidad</option>
                {informacionLocalidades &&
                  informacionLocalidades.data.map(opcion => (
                    <option key={opcion.IdLocalidad} value={opcion.IdLocalidad}>
                      {opcion.Nombre}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-6 col-lg-4">
              <select className="form-select" aria-label="Default select example" onChange={handleChangeActividad}>
                <option value="">Especialidad</option>
                {dataCertificados &&
                  dataCertificados.data
                    .filter(opcion => opcion.Activo === 1)
                    .map(opcion => (
                      <option key={opcion.IdCertificado} value={opcion.IdCertificado}>
                        {opcion.Nombre}
                      </option>
                    ))}
              </select>
            </div>
            <div className="col-md-6 col-lg-4">
              <select className="form-select" aria-label="Default select example" onChange={handleChangeIdioma}>
                <option value="">Idioma</option>
                <option key={'idioma_1'} value="Español">
                  Español
                </option>
                <option key={'idioma_2'} value="Deutsch">
                  Deutsch
                </option>
                <option key={'idioma_3'} value="Portugues">
                  Portugues
                </option>
                <option key={'idioma_4'} value="Italiano">
                  Italiano
                </option>
                <option key={'idioma_5'} value="Francais">
                  Francais
                </option>
                <option key={'idioma_6'} value="English">
                  English
                </option>
              </select>
            </div>
            <div className="col-md-6 col-lg-4">
              <input
                className="form-control"
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="Buscar por nombre"
                aria-label="default input example"
              />
            </div>

            <div className="col d-grid mb-5">
              <button onClick={handleConsultoresClick} type="button" className="btn btn-outline-primary">
                Filtrar búsqueda
              </button>
            </div>
          </div>
        </form>
        <div className="row g-3 g-md-4">
          {dataConsultores && dataConsultores.data.length == 0 && (
            <div className="col-md-12 text-start">
              <p className="lead">No se encuentran consultores con los filtros seleccionados.</p>
            </div>
          )}

          {!dataConsultores && (
            <>
              {Array.from({ length: 12 }).map((_, index) => (
                <div className="col-md-6 col-lg-4 d-grid align-items-stretch" key={index}>
                  <ConsultorCard />
                </div>
              ))}
            </>
          )}

          {getCurrentItems()?.map(data => (
            <>
              <div key={data.IdConsultor} className="col-md-6 col-lg-4 d-grid align-items-stretch">
                <div className="card">
                  <div className="card-header d-flex align-items-center">
                    <img src="/img/profile.svg" className="border border-1 rounded-circle card-profile me-2" alt="" />
                    <h4 className="mt-1">{data?.Nombre}</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <p className="icon-location ubicacion d-inline-flex align-items-center">
                          {data.localidad ? data.localidad.Nombre : ''}, {data.provincia ? data.provincia.Nombre : ''},{' '}
                          {data.pais ? data.pais.Nombre : ''}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer pb-3">
                    <div className="row">
                      <div className="col-6">
                        <button
                          type="button"
                          onClick={() => window.open('tel:+' + data.Telefono)}
                          className="btn btn-outline-primary btn-circle btn-lg me-1">
                          <i className="bi bi-telephone" />
                        </button>
                        <button
                          type="button"
                          onClick={() => window.open('mailto:' + data.Email)}
                          className="btn btn-outline-primary btn-circle btn-lg">
                          <i className="bi bi-envelope" />
                        </button>
                      </div>
                      <div className="col-6 text-end">
                        <button type="button" onClick={() => viewProfile(data)} className="btn btn-outline-primary">
                          ver perfil
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}

          {dataConsultores && (
            <div className="row my-5">
              <div className="col">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                      <button className="page-link" onClick={() => handlePageClick(currentPage - 1)} disabled={currentPage === 1}>
                        «
                      </button>
                    </li>
                    {generateVisiblePages(3).map(pageToShow => {
                      const isCurrentPage = currentPage === pageToShow;
                      return (
                        <li key={pageToShow} className={`page-item ${isCurrentPage ? 'active' : ''}`}>
                          <button className="page-link" onClick={() => handlePageClick(pageToShow)}>
                            {pageToShow}
                          </button>
                        </li>
                      );
                    })}
                    {currentPage < totalPages - 1 && (
                      <li className="page-item disabled">
                        <span className="page-link">...</span>
                      </li>
                    )}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                      <button
                        className="page-link"
                        onClick={() => handlePageClick(currentPage + 1)}
                        disabled={currentPage === totalPages}>
                        »
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          )}

          <section className="container mb-5">
            <div className="row g-3 g-md-4">
              {/* CARD */}
              <div className="col-md-6 d-grid align-items-stretch">
                <div className="card border-primary text-primary">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-md-9 col-lg-8 mb-5">
                        <h1>Deseo incorporarme al listado de Consultores</h1>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer pb-3">
                    <div className="row">
                      <div className="col text-start">
                        <a className="btn btn-outline-primary" href="/formaparte" role="button">
                          Comenzar
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* CARD */}
              <div className="col-md-6 d-grid align-items-stretch">
                <div className="card border-secondary text-secondary">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-md-9 col-lg-8 mb-5">
                        <h1>Deseo obtener mi Certificado</h1>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer pb-3">
                    <div className="row">
                      <div className="col text-start">
                        <a className="btn btn-outline-secondary" href="/certificado" role="button">
                          Comenzar
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default Consultores;
