import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Alert, Button, Container, Form, Modal } from 'react-bootstrap';
import { Navigate, useNavigate } from 'react-router-dom';
import { contactFormarParte, contactUser } from '../../store/user/userActions';
import { getPaises, getProvincias, getlocalidades, LocacionesData } from '@/store/locaciones/locacionesActions';

import { getCertificados, CertificadoData } from '@/store/certificados/certificadosActions';
import { reset, resetUserCreate } from '@/store/user/userSlice';
import * as Yup from 'yup';
import TabNavigation from '../molecules/TabNavigation';

export interface NewUserValues {
  nombre: string;
  telefono: string;
  email: string;
  pais: string;
  provincia: string;
  localidad: string;
  mensaje: string;
  acepto: boolean;
}

const FormaParteForm = () => {
  const initialValues: NewUserValues = {
    nombre: '',
    telefono: '',
    email: '',
    pais: '',
    provincia: '',
    localidad: '',
    mensaje: '',
    acepto: false,
  };
  const dispatch = useAppDispatch();
  const { newContactFormarParteError, newContactFormarParteLoading, newContactFormarParteSuccess } = useAppSelector(
    state => state.user,
  );
  const [addError, setAddError] = useState(false);
  const [addSuccess, setAddSuccess] = useState(false);

  const [valorPais, setValorPais] = useState('');
  const [valorProvincia, setValorProvincia] = useState('');
  const [valorLocalidad, setValorLocalidad] = useState('');
  const [valorActividad, setValorActividad] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [modalChangeState, setModalChangeState] = useState(false);
  const informacionPaises = useAppSelector(state => state.locaciones?.paisesData);
  const informacionProvincias = useAppSelector(state => state.locaciones?.provinciasData);
  const informacionLocalidades = useAppSelector(state => state.locaciones?.localidadesData);
  const [checked, setChecked] = useState(false);
  const dataCertificados = useAppSelector(state => state.certificados?.itemsData);

  useEffect(() => {
    dispatch(getPaises({ only_with_consultor: false }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCertificados({}));
  }, [dispatch]);

  useEffect(() => {
    if (valorPais) {
      dispatch(getProvincias({ id: valorPais, only_with_consultor: false }));
      setValorProvincia('');
    }
  }, [valorPais, dispatch]);

  useEffect(() => {
    if (valorProvincia) {
      dispatch(getlocalidades({ id: valorProvincia, only_with_consultor: false }));
      setValorLocalidad('');
    }
  }, [valorProvincia, dispatch]);

  const handleChangeA = (event: any) => {
    if (event.target.value == '0') {
      setValorPais('');
      setValorProvincia('');
      setValorLocalidad('');
    } else {
      setValorPais(event.target.value);
      setValorProvincia('');
      setValorLocalidad('');
      dispatch(getProvincias({ id: event.target.value, only_with_consultor: false }));
    }
  };

  const handleChangeB = (event: any) => {
    if (event.target.value == '0') {
      setValorProvincia('');
      setValorLocalidad('');
    } else {
      setValorProvincia(event.target.value);
      setValorLocalidad('');
      dispatch(getlocalidades({ id: event.target.value, only_with_consultor: false }));
    }
  };

  const handleChangeC = (event: any) => {
    setValorLocalidad(event.target.value);
  };

  const handleChangeActividad = (event: any) => {
    setValorActividad(event.target.value);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Este campo es obligatorio')
      .email('Este campo tiene que ser un email')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    nombre: Yup.string()
      .required('Este campo es obligatorio')
      .matches(/^([^0-9]*)$/, 'Ingresa un formato correcto')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    telefono: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    pais: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    provincia: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    localidad: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    mensaje: Yup.string()
      .required('Este campo es obligatorio')
      .min(0, 'Este campo es obligatorio')
      .matches(/^\s*\S[^]*$/, 'Este campo es obligatorio'),
    acepto: Yup.boolean().oneOf([true], 'Deseo ser incorporado al listado de Consultores.'),
  });

  useEffect(() => {
    if (newContactFormarParteError) {
      setAddError(true);
    }
    if (newContactFormarParteSuccess) {
      formik.resetForm();
      formik.setValues(initialValues);
      setAddSuccess(true);
    }
  }, [newContactFormarParteError, newContactFormarParteSuccess]);

  const formik = useFormik({
    initialValues,
    onSubmit: async values => {
      setAddError(false);
      setAddSuccess(false);

      const paisNombre = informacionPaises?.data.find(option => option.IdPais.toString() === values.pais)?.Nombre ?? '';
      const localidadNombre =
        informacionLocalidades?.data.find(option => option.IdLocalidad.toString() === values.localidad)?.Nombre ?? '';
      const provinciaNombre =
        informacionProvincias?.data.find(option => option.IdProvincia.toString() === values.provincia)?.Nombre ?? '';

      /*updatedValues.pais_nombre = pais_nombre;
      updatedValues.localidad_nombre = localidad_nombre;
      updatedValues.provincia_nombre = provincia_nombre;*/

      const updatedValues = { ...values, paisNombre, localidadNombre, provinciaNombre };

      await dispatch(contactFormarParte(updatedValues));
    },
    validationSchema: validationSchema,
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <>
      <Container>
        <section className="container mb-5">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card p-2">
                <div className="card-body">
                  {addSuccess && (
                    <div className="card-body py-5">
                      <h1 className="mb-5 text-primary">Gracias por enviarnos su contacto.</h1>
                      <p>
                        Gracias Me honra ya, el simple hecho de que Usted esté navegando esta página.
                        <strong> </strong>
                      </p>
                      <p>En unas horas nos comunicaremos con usted. Por cualquier consulta comuniquese a</p>
                      <p>
                        <a href="mailto:organizacionhp@gmail.com">organizacionhp@gmail.com</a>
                      </p>
                      <div className="col d-grid mb-5">
                        <a href="/consultores" className="btn btn-outline-primary">
                          Volver a Consultores
                        </a>
                      </div>
                    </div>
                  )}

                  {!addSuccess && (
                    <form action="">
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label htmlFor="exampleFormControlInput1" className="form-label">
                            Nombre y Apellido
                          </label>

                          <Form.Group className="col-md-12">
                            <Form.Control
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.nombre ? true : false}
                              required
                              onChange={formik.handleChange}
                              value={formik.values.nombre}
                              name="nombre"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.nombre}</Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="exampleFormControlInput1" className="form-label">
                            Teléfono
                          </label>
                          <Form.Group className="col-md-12">
                            <Form.Control
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.telefono ? true : false}
                              required
                              onChange={formik.handleChange}
                              value={formik.values.telefono}
                              name="telefono"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.telefono}</Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="exampleFormControlInput1" className="form-label">
                            Mail
                          </label>
                          <Form.Group className="col-md-12">
                            <Form.Control
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.email ? true : false}
                              required
                              onChange={formik.handleChange}
                              value={formik.values.email}
                              name="email"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-md-6 mb-3">
                          <label htmlFor="exampleFormControlInput1" className="form-label">
                            País
                          </label>
                          <Form.Group>
                            <Form.Select
                              aria-label="Default select example"
                              onChange={event => {
                                handleChangeA(event);
                                formik.handleChange(event);
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values.pais}
                              name="pais"
                              isInvalid={formik.errors.pais ? true : false}
                              required>
                              <option value="">Seleccione un país</option>
                              {informacionPaises &&
                                informacionPaises.data.map(opcion => (
                                  <option key={opcion.IdPais} value={opcion.IdPais}>
                                    {opcion.Nombre}
                                  </option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formik.errors.pais}</Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="exampleFormControlInput1" className="form-label">
                            Provincia
                          </label>
                          <Form.Group>
                            <Form.Select
                              aria-label="Default select example"
                              onChange={event => {
                                handleChangeB(event);
                                formik.handleChange(event);
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values.provincia}
                              name="provincia"
                              isInvalid={formik.errors.provincia ? true : false}
                              required>
                              <option value="">Seleccione una provincia</option>
                              {informacionProvincias &&
                                informacionProvincias.data.map(opcion => (
                                  <option key={opcion.IdProvincia} value={opcion.IdProvincia}>
                                    {opcion.Nombre}
                                  </option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formik.errors.provincia}</Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="exampleFormControlInput1" className="form-label">
                            Localidad
                          </label>
                          <Form.Group>
                            <Form.Select
                              aria-label="Default select example"
                              onChange={event => {
                                handleChangeC(event);
                                formik.handleChange(event);
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values.localidad}
                              name="localidad"
                              isInvalid={formik.errors.localidad ? true : false}
                              required>
                              <option value="">Seleccione una localidad</option>
                              {informacionLocalidades &&
                                informacionLocalidades.data.map(opcion => (
                                  <option key={opcion.IdLocalidad} value={opcion.IdLocalidad}>
                                    {opcion.Nombre}
                                  </option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{formik.errors.localidad}</Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="col-12 text-center">
                          <p>
                            <small>
                              En el caso de no encontrar la localidad correcta, seleccione una cualquiera, e indique la correcta
                              dentro de los comentarios
                            </small>
                          </p>
                        </div>

                        <div className="col-md-12 mb-3">
                          <label htmlFor="exampleFormControlInput1" className="form-label">
                            Comentarios
                          </label>
                          <Form.Group className="col-md-12">
                            <Form.Control
                              onBlur={formik.handleBlur}
                              isInvalid={formik.errors.mensaje ? true : false}
                              required
                              as="textarea"
                              rows={6}
                              maxLength={2000}
                              value={formik.values.mensaje}
                              onChange={formik.handleChange}
                              name="mensaje"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.mensaje}</Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        {dataCertificados &&
                          dataCertificados.data
                            .filter(opcion => opcion.Activo === 1)
                            .map(opcion => (
                              <div key={'cer_' + opcion.IdCertificado} className="col-md-4">
                                <Form.Check
                                  type="checkbox"
                                  id={`certificado-${opcion.IdCertificado}`}
                                  label={opcion.Nombre}
                                  value={opcion.Nombre}
                                  //checked={formik.values.localidad === opcion.IdLocalidad}
                                  onChange={event => {
                                    formik.handleChange(event);
                                  }}
                                />
                              </div>
                            ))}

                        <div className="col-12 text-center">
                          <hr />
                        </div>

                        <div className="col-12"></div>
                        <div className="col-12 mb-3">
                          <Form.Group className="col-md-12" controlId="acepto">
                            <Form.Check
                              type="checkbox"
                              id={`localidad-aceptar`}
                              name="acepto"
                              label="Deseo ser incorporado al listado de Consultores."
                              isInvalid={formik.errors.acepto ? true : false}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors.acepto}</Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="col-12 my-3 text-center">
                          <p>
                            <strong>
                              ATENCIÓN! Si usted ya está figurando en el listado y desee sumar un item o items nuevos, solo tilde
                              los items a sumar. No tilde los items que ya tenía antes.
                            </strong>
                          </p>
                        </div>
                        <div className="col-12 mb-3 d-grid">
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            disabled={formik.isValidating}
                            onClick={async () => {
                              const errors = await formik.validateForm();
                              console.log('formik.isValid', errors, formik.isValid);

                              if (Object.keys(errors).length === 0) {
                                setModalChangeState(true);
                              }
                            }}>
                            Siguiente <i className="bi bi-chevron-right" />
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal dialogClassName="custom-modal" backdrop="static" show={modalChangeState} onHide={() => setModalChangeState(false)}>
          <Modal.Header className="modal-header">
            <Modal.Title>Asumirse un Humano Puente</Modal.Title>
          </Modal.Header>

          <Modal.Body className="modal-body">
            <div className="modal-body">
              <p>
                {' '}
                <strong>Requisitos. Por favor, lea con atención</strong>{' '}
              </p>
              <p>
                {
                  'Declaro que he realizado la Apertura BEC (Apertura en Bioexistencia Consciente) y asumo la total responsabilidad por toda relación que tenga con los Consultantes o personas que atienda en relación a este campo o aplicando sus conocimientos ya que Humano Puente no puede corroborar el tipo de abordaje que llevaré a cabo más allá de sus recomendaciones. Declaro que a toda persona que me contacte a través de este listado o en mi carácter de Consultor/a en Bioexistencia Consciente le ofreceré únicamente lo incorporado en Humano Puente y en un formato actualizado. Acepto ser retirada/o de este listado de Consultores en caso que Humano Puente reciba quejas o comentarios sobre la utilización de otros abordajes o en formas que no son las indicadas y actualizadas por Humano Puente.  He sido desde un comienzo notificado/a acerca de que las prácticas que comparte Humano Puente no pretenden ser bajo ningún punto de vista una práctica médica y que Humano Puente es un camino de reencuentro con el espíritu, es decir, con uno mismo. En caso de no haber encontrado mi Localidad en el listado, seleccionare otra e informare a organizacionhp@gmail.com cual es la localidad correcta.'
                }
              </p>
              <p> </p>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={checked}
                  onChange={e => setChecked(e.target.checked)}
                  defaultValue=""
                  id="flexCheckDefault"
                />

                <label className="form-check-label" htmlFor="flexCheckDefault">
                  <p className="small-text">Acepto los términos y condiciones.</p>
                </label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <Button
              disabled={!checked}
              onClick={() => {
                setModalChangeState(false);
                formik.handleSubmit();
              }}
              variant="btn btn-primary">
              Aceptar
            </Button>
          </Modal.Footer>
        </Modal>

        {addError && (
          <Alert variant="danger" dismissible onClose={() => setAddError(false)}>
            <Alert.Heading>¡Ups, algo salió mal!</Alert.Heading>
            <p>Intenta nuevamente</p>
          </Alert>
        )}
      </Container>
      {newContactFormarParteLoading && <div>Aguarde un instante...</div>}
    </>
  );
};

export default FormaParteForm;
