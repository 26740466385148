import React, {  useEffect, useState } from 'react';
import { loginUser } from '@/store/user/userActions';
import { LoginUserProps } from '@/interfaces/user';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { useNavigate} from 'react-router-dom';
import {
  
  getConsultores,
} from '@/store/consultores/consultoresActions';
import {
  getCertificados,
  CertificadoData
} from '@/store/certificados/certificadosActions';
import {   Button, Form, Modal } from 'react-bootstrap';
 
import ConsultorCard from '@/components/loading/ConsultorCard';
 

const ConsultoresCampus: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  //const dataConsultores = useAppSelector((state) => state.consultores?.consultoresEData);
 


  const [valorActividad, setValorActividad] = useState('');
  const [valorIdioma, setValorIdioma] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [mostrarSeccion, setMostrarSeccion] = useState(false); 

  const dataConsultores = useAppSelector((state) => state.consultores?.consultoresData);
  const dataCertificados = useAppSelector((state) => state.certificados?.itemsData);

  // Estado para currentPage con valor inicial 1
  const [currentPage, setCurrentPage] = useState(1);



  const itemsPerPage = 30;
  const dataConsultoresCount = dataConsultores?.data.length ?? 0;
  const totalPages = Math.ceil(dataConsultoresCount / itemsPerPage);

  const generateVisiblePages = (  maxPagesToShow: number) => {
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
    let startPage = Math.max(1, currentPage - halfMaxPagesToShow);
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
  
    if (endPage - startPage + 1 < maxPagesToShow) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }
  
    const pagesArray = [...Array(endPage - startPage + 1)].map((_, index) => startPage + index);
  
    return pagesArray;
  };

  const handlePageClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };



  const getCurrentItems = () => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return dataConsultores?.data.slice(indexOfFirstItem, indexOfLastItem);
  };


  const handleChangeIdioma = (event: any) => {
    setValorIdioma(event.target.value);
  };

  useEffect(() => {
    dispatch(getCertificados({}));
  }, [dispatch]);

 

  useEffect(() => {
    dispatch(getConsultores({ page: 0, tipo: "campus" }));
  }, [dispatch]);

  const handleConsultoresClick = () => {
    setCurrentPage(1);
    dispatch(getConsultores({ page: 0, IdPais: '', IdProvincia: '', IdLocalidad: '', Nombre: inputValue, actividad: valorActividad, tipo: "campus", idioma: valorIdioma  }));
  };

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  }


  const handleChangeActividad = (event: any) => {
    setValorActividad(event.target.value);
  };


  return (
    <>
 


  {/* Breadcrumb */}
  <section className="container d-none d-lg-block mt-2">
    <div className="row">
      <div className="col-12">
        <div className="border-top py-2">
          <nav style={{}} aria-label="breadcrumb" className="d-none d-md-block">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Inicio</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Consultores Campus
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </section>
  {/* Header */}
  <header className="container my-2 my-md-5">
    <div className="row">
      <div className="col-md-4">
        <h3>Area para</h3>
        <h1 className="display-4 mb-4">Consultores Campus</h1>
       {/* <a className="btn btn-outline-primary mb-5" href="https://foro.humanopuente.com.ar/" target='_blank' role="button">Acceso a Consultores</a> */}
      </div>
      <div className="col-md-8 text-start">
        <p>
        Bienvenido al área de Consultores Certificados de Humano Puente.   </p>
        <p>En esta misma sección podrás encontrar a partir de Enero 2025 a los Consultores Certificados en temas como Infertilidad, Histaminosis, Migrañas, Adicciones, Obesidad, Bulimia y Anorexia, Alergias, Fobias y Ataques de Pánico, Relaciones de Pareja y más. 
        </p><p>En este momento los Consultores que encontrarás en este listado se encuentran en estado de formación.  </p>
        <p>Te esperamos a partir de Enero 2025.  </p>

       
        <p>Pablo Almazán.</p>


      </div>
      <div className="col-12" style={{ textAlign: 'right' }} >

      <button  onClick={() => navigate("/consultores")} type="button" className="btn btn-outline-primary" style={{ padding: '6px 80px' }}  >
                Volver
              </button>

        <br/>
        <br/>   <br/>   <br/>   <br/>   <br/>   <br/>   <br/>   <br/>   <br/>   <br/>   <br/>   <br/>   <br/>  
        <hr />
      </div>
    </div>
  </header>
  {mostrarSeccion && (
  <section className="container"   >
    <div className="row g-4">
      <div className="col-12 col-md-12">
        <header className="container">
          <div className="row g-4">
            <div className="col-12">
              <h1>Consultores</h1>
            </div>
          </div>
        </header>
        <section className="container my-2">
          <form>
            <div className="row">
            <div className="col-md-4">
              <select className="form-select" aria-label="Default select example" onChange={handleChangeIdioma}>
                <option value="">Seleccionar Idioma</option>
                   <option key={'idioma_1'}  value="Español">Español</option>
                  <option key={'idioma_2'}  value="Deutsch">Deutsch</option>
                  <option key={'idioma_3'}  value="Portugues">Portugues</option>
                  <option key={'idioma_4'}  value="Italiano">Italiano</option>
                  <option key={'idioma_5'}  value="Francais">Francais</option>
                  <option key={'idioma_6'}  value="English">English</option>
  
              </select>
            </div>
            <div className="col-md-4 col-lg-4">
              <select className="form-select" aria-label="Default select example" onChange={handleChangeActividad}>
                <option value="">Especialidad</option>
                {dataCertificados && dataCertificados.data
                  .filter((opcion) => opcion.tipo === 'Campus')
                  .map((opcion) => (
                  <option key={opcion.IdCertificado} value={opcion.IdCertificado}>
                    {opcion.Nombre}
                  </option>
                ))}
              </select>
            </div>
              <div className="col-md-4">
                <input
                  className="form-control"
                  type="text"
                  value={inputValue} onChange={handleInputChange}
                  aria-label="default input example"
                  placeholder="buscar consultores por nombre"
                />
              </div>
            
              <div className="col d-grid mb-2">
                
              </div>
            </div>
          </form>
          <div className="col d-grid mb-5 mt-3">
              <button onClick={handleConsultoresClick} type="button" className="btn btn-outline-primary">
                Filtrar búsqueda
              </button>
            </div>
          <div className="row g-3 g-md-4 mb-5">


          {dataConsultores && dataConsultores.data.length == 0 && (
            <div className="col-md-12 text-start">
              <p className="lead">
                No se encuentran consultores con los filtros seleccionados.
              </p>
            </div>
          )}

          {(!dataConsultores) && (
            <>
              {Array.from({ length: 12 }).map((_, index) => (
                <div className="col-md-6 col-lg-4 d-grid align-items-stretch" key={index}>
                  <ConsultorCard />
                </div>
              ))}
            </>
          )}

            {/* CARD */}
            {getCurrentItems()?.map(data => ( 
            <div  key={data.IdConsultor} className="col-md-4 col-lg-4 d-grid align-items-stretch">
              <div className="card">
                <div className="card-header d-flex align-items-center">
                  <img
                    src="./img/profile.svg"
                    className="border border-1 rounded-circle card-profile me-2"
                    alt=""
                  />
                  <h4 className="mt-1">{data.Nombre} </h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <p className="icon-location ubicacion d-inline-flex align-items-center">
                      {data.localidad.Nombre}, {data.provincia.Nombre}, {data.pais.Nombre}
                      </p>
                    
                    </div>
                  
                    <div className="col-12">
                      <p className="icon-phone ubicacion d-inline-flex align-items-center">
                      {data.Telefono} 
                      </p>
                    
                    </div>
                    <div className="col-12">
                      <p className="icon-mail ubicacion d-inline-flex align-items-center">
                      {data.Email} 
                      </p>
                    
                    </div>
                  </div>
                </div>
               {/*  <div className="card-footer pb-3">
                  <div className="row">
                    <div className="col-6">
                      <button
                        type="button"
                        onClick={() => window.open("tel:+" +  data.phone)}
                        className="btn btn-outline-primary btn-circle btn-lg me-1"
                      >
                        <i className="bi bi-telephone" />
                      </button>
                      <button
                        type="button"
                        onClick={() => window.open("mailto:" + data.email)}
                        className="btn btn-outline-primary btn-circle btn-lg"
                      >
                        <i className="bi bi-envelope" />
                      </button>
                    </div>
                   
                  </div>
                </div>*/}
              </div>
            </div>
            ))} 
            </div>

            {dataConsultores && (
        <div className="row my-5">
          <div className="col">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-center">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                  <button
                    className="page-link"
                    onClick={() => handlePageClick(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    «
                  </button>
                </li>
                {generateVisiblePages(3).map((pageToShow) => {
                  const isCurrentPage = currentPage === pageToShow;
                  return (
                    <li key={pageToShow} className={`page-item ${isCurrentPage ? 'active' : ''}`}>
                      <button
                        className="page-link"
                        onClick={() => handlePageClick(pageToShow)}
                      >
                        {pageToShow}
                      </button>
                    </li>
                  );
                })}
                {currentPage < totalPages - 1 && (
                  <li className="page-item disabled">
                    <span className="page-link">...</span>
                  </li>
                )}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                  <button
                    className="page-link"
                    onClick={() => handlePageClick(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    »
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      )}
        </section>
      </div>
    
    </div>
  </section>
  )}
</>


 
  );
};

export default ConsultoresCampus;
