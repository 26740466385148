import React, { useCallback, useState } from 'react';
import { loginUser } from '@/store/user/userActions';
import { LoginUserProps } from '@/interfaces/user';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import LoginForm from '@/components/organisms/LoginForm';
import MyNav2 from '@/components/templates/MyNav2';


const ConsultoresMenu: React.FC = () => {


  const [isOpen, setisOpen] = useState(false);
  const menuClass = `col-12 my-3 collapse ${isOpen ? " show" : ""}`;
  const toggleOpen = () => setisOpen( !isOpen);

  const dispatch = useAppDispatch();


  return (
    <>
      <header className="consultores">
      <MyNav2/>
    {/* Breadcrumb */}
    <section className="container d-none d-lg-block mt-2">
      <div className="row">
        <div className="col-12">
          <div className="border-top py-2">
            <nav style={{}} aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-light">
                <li className="breadcrumb-item">
                  <a href="/">Inicio</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                Consultores
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>
    {/* Titulo */}
    <section className="container text-white">
      <div className="row">
        <div className="col-md-9 col-lg-6 my-5">
          <h1 className="display-3 mt-5">Listados de Consultores</h1>
          <p className="lead mb-5">
          Bienvenidos todos. Felicitaciones por la valentía de sanar la
          experiencia Humana. En esta sección podrás encontrar tres listados. En
          todos ellos figuran solamente consultores actualizados: El listado
          GENERAL, el listado de CONSULTORES CERTIFICADOS (desde enero 2025) y
          el listado de CONSULTORES CERTIFICADOS EN EMPRESAS Y ECONOMÍA.
          </p>
        </div>
      </div>
    </section>
    </header>
   
 
  <section className="container py-5">
    <div className="row">
      <div className="col-lg-6">
        <h2 className="text-secondary">Consultores</h2>
      </div>
      <div className="col-lg-6">
        <p className="mb-3">
          Gracias a su constante evolución y profundización, Humano Puente puede
          hoy poner a disposición listados de consultores cada vez más
          profundos, humanos y con mayor experiencia. Los Consultores
          Certificados de diferentes áreas que encontrarás aquí, realizan una
          constante ampliación y actualización de sus conocimientos en clases y
          encuentros quincenales dentro de Campus específicos de cada tema,
          acompañados por Pablo Almazán y Lucrecia&nbsp;Bianchi.{" "}
        </p>
      </div>
    </div>
  </section>
  <section className="container">
    <div className="row">
      <div className="col-12">
        <div className="border-top border-dark pt-2">
          <h5 className="text-primary">
            <strong>Listados de Consultores</strong>
          </h5>
          <div className="row text-white">
            <div className="col-md-4 my-2">
              <a href="/consultores-general">
                <div className="circlelist1 ratio ratio-1x1 paso-01list">
                  <div className="d-flex flex-column align-items-center justify-content-center text-center">
                    <h2>Listado General</h2>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-4 my-2">
              <a href="/consultores-campus">
                <div className="circlelist2 ratio ratio-1x1 paso-02list">
                  <div className="d-flex flex-column align-items-center justify-content-center text-center">
                    <h2>Consultores Certificados</h2>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-4 my-2">
              <a href="/empresas">
                <div className="circlelist3 ratio ratio-1x1 paso-03list">
                  <div className="d-flex flex-column align-items-center justify-content-center text-center">
                    <h2>Consultores Certificados en Empresas y Economía</h2>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="py-5"></section>
  </>

  );
};

export default ConsultoresMenu;
